body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Background and Foreground colors */
body {
  background-color: #e6e6dc;
  color: #d5d5c8;
  font-family: Arial, sans-serif;
}

/* Main Headings */
h1,
h2,
h3 {
  font-size: 39px;
  font-family: Arial, sans-serif;
  color: black;
  margin: 30px
}

/* Sub Headings */
h4,
h5,
h6 {
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: grey;
}

/* Text on Screen */
p,
span,
label {
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #d5d5c8;
}

/* Blue Background Color */
.button-blue {
  background-color: #0071BC;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.button-blue:hover {
  background-color: #005a99;
}

/* Orange Border */
.with-orange-border {
  border: 2px solid #f38d07;
}

/* Modal Styling */
.modal {
  top: 0;
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(128, 128, 128, 0.39);
}

.modal-content {
  background-color: #000000;
  padding: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.modal-camera-ref-Img {
  display: flex;
  justify-content: space-between;

}

.modal-camera-ref-Img div {
  display: flex;
  align-items: center;
}

.modal-content h2 {
  color: #d5d5c8;
}

.modal-content img {
  border: 2px solid #f38d07;
  /* Orange Border around images */
}

.modal-content button {}

button {
  background-color: #0071BC;
  /* Blue background for buttons */
  color: white;
  padding: 15px 23px;
  border: none;
  cursor: pointer;
  font-size: 24px;

  border-radius: 4px;
}

button:hover {
  background-color: #005a99;

}

/* Image container with orange border */
.image-container {
  border: 2px solid #f38d07;
  padding: 10px;
}

.nav-container {
  background-color: white;
  height: 100px;
  border-bottom: 10px solid #0280d3;
  display: flex;
  align-items: center;
  padding: 0px 40px;
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

*:disabled {
  opacity: 0.5;
  cursor: not-allowed;

}

.loader {
  width: 28px;
  height: 28px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@media screen and (max-width: 600px) {
  button {
    background-color: #0071BC;
    /* Blue background for buttons */
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    border-radius: 4px;
  }

  h2 {
    font-size: 20px;
  }
}



@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



/* Container for centering the table */
.table-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* Center horizontally */
  align-items: center;
  /* Full height to ensure vertical centering */
  border-radius: 4px;
 
}

/* Styling the table */
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 1.4em;
  font-family: 'Arial', sans-serif;
  min-width: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
}

/* Table header styling */
.styled-table thead tr {
  background-color: #00AFE6;
  color: #ffffff;
  text-align: center;
}

/* Table body rows */
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

/* Hover effect */
.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Table cell padding */
.styled-table td,
.styled-table th {
  padding: 12px 15px;
}

/* Last row without border */
.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #00AFE6;
}